


















































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";

@Component
export default class Login extends Vue {
  @Action("setLoggedIn", { namespace: "login" }) setLoggedIn!: Function;
  emailAddress = "";
  password = "";
  passwordInputType = "password";
  loginError: string | boolean = "" || false;
  forgotPasswordForm = false;

  async login() {
    this.loginError = false;

    if (this.emailAddress && this.password) {
      const response: void | AxiosResponse = await axios({
        baseURL: "https://vvbeta.nl/",
        url: "oauth/token",
        method: "post",
        data: {
          grant_type: "password",
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          username: this.emailAddress,
          password: this.password,
          scope: ""
        }
      }).catch((error: AxiosError) => {
        console.log(error);
      });

      if (response && response.data) {
        await this.setLoggedIn();
      } else {
        this.loginError = "Uw gegevens zijn niet juist, probeer het nogmaals";
      }
    } else {
      this.loginError = "Vul eerst een emailadres en een wachtwoord in.";
    }
  }

  async requestPassword() {
    if (this.emailAddress) {
      await axios
        .post("recover-account", {
          email: this.emailAddress
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      this.forgotPasswordForm = false;
    }
  }
}
